import Header from "../Headers/Header";
import Footer from "../Footer/Footer";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
// import numSeparator from "../../utils/numSeparator";
import "./medicine.css";
import {React, useEffect, useState} from "react";
import {Link, useSearchParams, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {CheckAndHandleError} from "../../axios/HandleAcsess";
// import Loading from "../Loading/Loading";
import {message, Skeleton} from "antd";
// import {toArray} from "antd/es/form/util";
import ClearIcon from '@mui/icons-material/Clear';
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import {ShowDisplayFilterMobile} from "./MedicineA";
import {useMediaQuery} from "react-responsive";




export default function MedicineB() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate()


    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [showFilterPage, setShowFilterPage] = useState(false);
    const [totalDocs, setTotalDocs] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(15)
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showButtonDeleteAllFilter, setShowButtonDeleteAllFilter] = useState(false);
    const [showDisplayFilter, setShowDisplayFilter] = useState(false);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});



    const [brandNameFA, setBrandNameFA] = useState("")
    const [brandNameEN, setBrandNameEN] = useState("")
    const [genericNameFA, setGenericNameFA] = useState("")
    const [genericNameEN, setGenericNameEN] = useState("")
    const [genericCode, setGenericCode] = useState("")
    const [quatityInPack, setQuatityInPack] = useState("")
    const [supplirCompany, setSupplirCompany] = useState("")
    const [producerComopany, setProducerComopany] = useState("")
    const [ircCode, setIrcCode] = useState("")
    const [pricePharmacy, setPricePharmacy] = useState("")
    const [priceDistributor, setPriceDistributor] = useState("")
    const [searchAllFilter, setSearchAllFilter] = useState("")


    const chips = [brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, quatityInPack, supplirCompany, producerComopany, ircCode, pricePharmacy, priceDistributor, searchParams.get("search")]


    function handlePage(page) {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        } else setCurrentPage(1)
    }

    function deleteUrl(key) {

        // const searchParams = new URLSearchParams(window.location.search);
        if (key)
            searchParams.delete(key);
        navigate(location.pathname + "?" + searchParams.toString())

    }

    function addUrlParams(key, value) {

        if (value)
            searchParams.set(key, value)
        navigate(location.pathname + "?" + searchParams.toString())

    }


    function sendDateSearchAllFilterToApi() {
        searchParams.set("search", searchAllFilter)
        navigate(location.pathname + "?" + searchParams.toString())
    }

    function check(s) {
        var PersianOrASCII = /[آ-ی]|([a-zA-Z])/;
        var m;
        if ((m = s.match(PersianOrASCII)) !== null) {
            if (m[1]) {
                return false;
            }
            else { return true; }
        }
        else { return true; }
    }

    function selectedNumber(value) {
        setItemPerPage(value)
        setShowDropdown(false)
        setCurrentPage(1)
    }

    function deleteAllFilter() {

        // for (const [key] of searchParams) {
        //     deleteUrl(key)
        // }
        setSearchAllFilter("")
        navigate("/MedicineB")
        // setShowButtonDeleteAllFilter(false)

    }


    function deleteCustomFilter(event) {

        for (const [key, value] of searchParams) {
            if (value === event)
                deleteUrl(key)
        }

    }

    useEffect(() => {

        if (Boolean(brandNameFA || brandNameEN || genericNameFA || genericNameEN || genericCode || quatityInPack || supplirCompany || producerComopany || ircCode || pricePharmacy || priceDistributor || searchParams.get("search")))
            setShowButtonDeleteAllFilter(true)
        else setShowButtonDeleteAllFilter(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, quatityInPack, searchParams.get("search"), supplirCompany, producerComopany, pricePharmacy, priceDistributor, ircCode]);


    useEffect(() => {

        setBrandNameFA(searchParams.get("brandNameFA") || "")
        setBrandNameEN(searchParams.get("brandNameEN") || "")
        setGenericNameFA(searchParams.get("genericNameFA") || "")
        setGenericNameEN(searchParams.get("genericNameEN") || "")
        setGenericCode(searchParams.get("genericCode") || "")
        setQuatityInPack(searchParams.get("quatityInPack") || "")
        setSupplirCompany(searchParams.get("supplirCompany") || "")
        setProducerComopany(searchParams.get("producerComopany") || "")
        setIrcCode(searchParams.get("ircCode") || "")
        setPricePharmacy(searchParams.get("pricePharmacy") || "")
        setPriceDistributor(searchParams.get("priceDistributor") || "")
        setSearchAllFilter(searchParams.get("search") || "")


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.toString()]);

    useEffect(() => {

        const delayedSetData = setTimeout( () => {
            searchParams.set("search", searchAllFilter)
            navigate(location.pathname + "?" + searchParams.toString())
        }, 800)

        return () => clearTimeout(delayedSetData)
    }, [searchAllFilter]);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get("https://kimipedia-back.liara.run/medicine/v1/get-all-medicine-b-user", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
                    },
                    params: {
                        limit: itemPerPage,
                        page: currentPage,
                        generic_name_la: genericNameEN,
                        generic_name_fa: genericNameFA,
                        generic_code: genericCode,
                        brand_name_fa : brandNameFA,
                        brand_name_la: brandNameEN,
                        quantity_in_pack: quatityInPack,
                        supplier_company: supplirCompany,
                        producer_company: producerComopany,
                        irc_code: ircCode,
                        price_pharmacy: pricePharmacy,
                        price_distributor: priceDistributor,
                        search: searchParams.get("search")
                    }
                });
                const data = response.data.data.main;
                setTotalDocs(data.totalDocs)
                setCurrentItems(data.docs);
                setTotalPages(data.totalPages);
                setLoading(false)
            } catch (error) {
                if (error.message === "Network Error") {
                    message.error("اینترنت خود را بررسی کنید ..!")
                }else if ( error.response ) {
                    message.error(error.response.data.message);
                } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
                CheckAndHandleError(error);
                setLoading(false)
            }
        };
        fetchData();

    }, [currentPage, searchParams.get("search"), itemPerPage, brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, quatityInPack, supplirCompany, producerComopany, ircCode, pricePharmacy, priceDistributor])

        // brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, quatityInPack, barndNameOwner, searchAllFilter, supplirCompany, producerComopany, ircCode, gtinCode, pricePharmacy, priceDistributor])


    return (
        <>
            <Header/>
            <div className="main md:p-2 p-7 mb-12">
                <div
                    className="md:gap-3 md:flex md:flex-col article-header flex flex-row justify-between items-center md:items-start">
                    <div className="article-title flex flex-col gap-2">
                        <p className="md:text-[11px] md:flex font-normal text-mainColor">
                            &gt;&gt; دسته بندی B
                        </p>
                        <h2 className="text-[32px] md:text-[16px] font-black">
                            داروهای پایه (کلیه داروها - داروهای آزاد)
                        </h2>
                    </div>

                    <div className="flex gap-6">

                        <div className="hidden md:flex md:flex-col">

                            <div className="flex flex-col">
                                <p className="filter h-[27px] text-mainColor font-bold md:text-[12px] text-[14px] mb-[4px]">
                                    <FormatListNumberedRtlIcon style={{fontSize: "22px"}}/> تعداد نمایش </p>
                                <div
                                    className="w-[120px] flex justify-between hover:cursor-pointer rounded-[8px] border-[2px] text-right text-[15px] placeholder:text-right bg-lightColor p-2"
                                    onClick={() => setShowDropdown(!showDropdown)}>
                                    <p className="mr-2 text-gray-400">{itemPerPage}</p>
                                    <KeyboardArrowDownIcon
                                        className={`text-mainColor ${showDropdown ? `rotate-180` : null}`}/>
                                </div>
                            </div>

                            <div className="flex justify-center">
                                {showDropdown ?
                                    <div className="absolute flex justify-center mt-1">
                                        <ul className="flex flex-col gap-1 p-1 pr-4 w-[120px] bg-lightColor rounded-[8px]">
                                            <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                onClick={() => selectedNumber(15)}>
                                                15
                                            </li>
                                            <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                onClick={() => selectedNumber(30)}>
                                                30
                                            </li>
                                            <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                onClick={() => selectedNumber(50)}>
                                                50
                                            </li>
                                        </ul>
                                    </div>
                                    : null}
                            </div>
                        </div>

                        <div className="search flex flex-col md:w-full md:justify-between">

                            <div
                                className="filter flex justify-between font-bold md:text-[12px] text-[14px] h-[26px] text-mainColor mb-[4px]">
                                <div className="hover:cursor-pointer" onClick={() => setShowFilterPage(true)}>
                                    <TuneIcon style={{fontSize: "22px"}}/> فیلتر جستجو
                                </div>
                                <div className="hidden md:flex md:hover:cursor-pointer">
                                    {showButtonDeleteAllFilter
                                        ?
                                        <button onClick={() => setShowDisplayFilter(true)}> نمایش فیلتر ها &gt;</button>
                                        :
                                        null}
                                </div>
                            </div>

                            <div className="search-input md:flex md:justify-end">
                                <input
                                    type="text"
                                    value={searchAllFilter}
                                    autoComplete="off"
                                    placeholder="عبارت مورد نظر را وارد کنید"
                                    className="search-f md:w-[80%] w-[250px] border-[2px] text-right text-[16px] md:placeholder:text-[12px] placeholder:text-right placeholder:text-gray-400 bg-lightColor p-2"
                                    onChange={(e) => setSearchAllFilter(e.target.value)}
                                />
                                <button className="serach-i bg-mainColor text-white p-2"
                                        onClick={sendDateSearchAllFilterToApi}
                                >
                                    <ManageSearchIcon/>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="md:hidden flex gap-3 mt-4 md:flex-col">
                    {showButtonDeleteAllFilter ?
                        <div
                            className="flex gap-1 bg-mainColor opacity-75 rounded-[5pc] hover:cursor-pointer pl-3 pr-3 pt-1 pb-1"
                            onClick={deleteAllFilter}>

                            <p className="text-[12px] text-white font-[900] m-auto">حذف تمام فیلتر ها</p>
                        </div> : null}
                    {chips.map((iteam, index) => (
                        iteam &&
                        <div key={index}
                             className="flex gap-1 rounded-[5pc] bg-lightColor border-mainColor border-opacity-20 border-[2px] hover:cursor-pointer pl-3 pr-3 pt-1 pb-1"
                             onClick={() => deleteCustomFilter(iteam)}>
                            <p><ClearIcon style={{fontSize: "16px", color: "#00C0C0", margin: "auto"}}/></p>
                            <p className="text-[12px] opacity-65 text-mainColor font-black m-auto">{iteam}</p>
                        </div>
                    ))}
                </div>

                <div className="article-main mt-4">
                    <div>
                        <table className="text-center w-full text-sm rounded-[8px] border-2 border-gray-100">
                            <thead>
                            <tr className="bg-[#F9F9F9]">
                                <th></th>
                                <th>نام برند</th>
                                <th>نام ژنریک</th>
                                <th className="md:hidden">شرکت تولید کننده</th>
                                <th className="md:hidden">تامین کننده</th>
                                <th>عملیات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? (
                                [...Array(7)].map((_, index) => (
                                    <tr key={index}>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className=" md:hidden box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className=" md:hidden box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                currentItems.length > 0 ?
                                    currentItems.map((item, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-[#F9F9F9]'}>
                                        <td className="box-border text-mainColor">{index + 1 + (itemPerPage * (currentPage - 1))}</td>
                                            <td>{item.brand_name_fa?.length > 30 ? <>{item.brand_name_fa.slice(0, 30) ?? "-"} ...</> : item.brand_name_fa ?? "-"}</td>
                                            <td className="">{item.generic_name_fa?.length > 30 ? <> {item.generic_name_fa.slice(0, 30) ?? "-"} ...</> : item.generic_name_fa ?? "-"}</td>
                                            <td className="md:hidden">{item.producer_company?.length > 30 ? ( check(item.producer_company) ? <>{item.producer_company.slice(0, 30) ?? "-"} ...</> : <>... {item.producer_company.slice(0, 30) ?? "-"}</> ) : (item.producer_company ?? "-") }</td>
                                            <td className="md:hidden">{item.supplier_company?.length > 30 ? ( check(item.supplier_company) ? <>{item.supplier_company.slice(0, 30) ?? "-"} ...</> : <>... {item.supplier_company.slice(0, 30) ?? "-"}</> ) : item.supplier_company ?? "-"}</td>
                                            <td className="text-thirdColor">
                                                <Link to={`/medicine-b/${item._id}`}>
                                                    <div className="flex items-center font-[300] justify-center gap-1">
                                                        <div
                                                            className="rounded-[5px] md:hidden p-1 w-auto h-auto flex items-center justify-center">
                                                            <SearchIcon/>
                                                        </div>
                                                        <span>نمایش اطلاعات</span>
                                                    </div>
                                                </Link>
                                            </td>
                                        </tr>
                                    )) :
                                    <tr className="bg-lightColor">
                                        <td className="text-[16px]" colSpan="6">دارویی یافت نشد!</td>
                                    </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalDocs > itemPerPage ?
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setItemPerPage={setItemPerPage}
                        setCurrentPage={setCurrentPage}
                        itemPerPage={itemPerPage}
                        onPageChange={handlePage}/>
                    :
                    null
                }
            </div>

            <Footer/>

            {showFilterPage ?
                <FindTitleByFilter setShowFilterPage={setShowFilterPage}/>
                :
                null
            }

            {isMobile ?
                showDisplayFilter ?
                    <ShowDisplayFilterMobile
                        setShowDisplayFilter={setShowDisplayFilter}
                        chips={chips} showButtonDeleteAllFilter={showButtonDeleteAllFilter}
                        deleteAllFilter={deleteAllFilter} deleteCustomFilter={deleteCustomFilter}/>
                    :
                    null
                :
                null}

        </>
    );
}

export function Pagination({currentPage, totalPages, onPageChange, setItemPerPage, setCurrentPage, itemPerPage}) {

    const [valueSetPage, setValueSetPage] = useState();
    const [showDropdown, setShowDropdown] = useState(false);


    useEffect(() => {
        setValueSetPage(currentPage)
    }, [currentPage])


    function setNumberInputPage() {
        onPageChange(valueSetPage)
    }

    function selectedNumber(value) {
        setItemPerPage(value)
        setShowDropdown(false)
        setCurrentPage(1)
    }


    return (

        <div className="flex justify-center gap-5">

            <div className="flex flex-col">
                <div
                    className="pagination transition ease-in-out delay-150 pt-5 text-mainColor flex items-center justify-center gap-3">

                    <button
                        className={` ${currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >

                        <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                            <ArrowForwardIcon style={{fontSize: "18px"}}/>
                        </div>

                    </button>


                    <div className="flex text-black md:text-[12px] font-bold">
                        <p>{currentPage} از {totalPages} صفحه</p>
                    </div>


                    <button
                        className={` ${currentPage === totalPages ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >

                        <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                            <ArrowBackIcon style={{fontSize: "18px"}}/>
                        </div>

                    </button>


                </div>

                <div className="flex justify-center pt-2 items-center">

                    <input
                        className="w-[7.5rem] md:w-[6rem] bg-lightColor border-2 border-l-0 outline-none focus:border-2 pr-2 focus:border-mainColor focus:border-l-0 rounded-r-[5px]"
                        placeholder="برو به صفحه ..."
                        type="number"
                        onChange={(e) => setValueSetPage(e.target.value)}
                    />
                    <p className="bg-mainColor border-2 border-mainColor rounded-l-[5px] mt-auto mb-auto hover:cursor-pointer text-white "
                       onClick={setNumberInputPage}
                    >
                        <PlayForWorkIcon className="rotate-90"/>
                    </p>

                </div>
            </div>

            <div className="flex flex-col justify-end md:hidden">

                <div className="flex flex-col">
                    <p className="flex text-mainColor md:text-[12px] font-bold mb-[10px]">
                        <FormatListNumberedRtlIcon style={{fontSize: "22px"}}/> تعداد نمایش </p>
                    <div
                        className=" flex justify-between w-[8rem] md:w-[6rem] bg-lightColor border-2 rounded-[5px]"
                        onClick={() => setShowDropdown(!showDropdown)}>
                        <p className="mr-2 text-gray-400">
                            {itemPerPage}
                        </p>
                        <KeyboardArrowDownIcon
                            className={`text-mainColor ${showDropdown ? `rotate-180` : null}`}
                        />
                    </div>
                </div>

                <div className="flex justify-center">
                    {showDropdown ?
                        <div className="absolute flex justify-center mt-1">
                            <ul className={`flex flex-col gap-1 p-1 pr-4 w-[130px] bg-lightColor rounded-[8px] ${showDropdown ? `mb-14` : null}`}>
                                <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                    onClick={() => selectedNumber(15)}
                                >
                                    15
                                </li>
                                <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                    onClick={() => selectedNumber(30)}
                                >
                                    30
                                </li>
                                <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                    onClick={() => selectedNumber(50)}
                                >
                                    50
                                </li>
                            </ul>
                        </div>
                        : null}
                </div>
            </div>

        </div>
    );
}


export function FindTitleByFilter(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);


    const [brandNameFA, setBrandNameFA] = useState(searchParams.get("brandNameFA") || "")
    const [brandNameEN, setBrandNameEN] = useState(searchParams.get("brandNameEN") || "")
    const [genericNameFA, setGenericNameFA] = useState(searchParams.get("genericNameFA") || "")
    const [genericNameEN, setGenericNameEN] = useState(searchParams.get("genericNameEN") || "")
    const [genericCode, setGenericCode] = useState(searchParams.get("genericCode") || "")
    const [quatityInPack, setQuatityInPack] = useState(searchParams.get("quatityInPack") || "")
    const [supplirCompany, setSupplirCompany] = useState(searchParams.get("supplirCompany") || "")
    const [producerComopany, setProducerComopany] = useState(searchParams.get("producerComopany") || "")
    const [ircCode, setIrcCode] = useState(searchParams.get("ircCode") || "")
    const [pricePharmacy, setPricePharmacy] = useState(searchParams.get("pricePharmacy") || "")
    const [priceDistributor, setPriceDistributor] = useState(searchParams.get("priceDistributor") || "")


    function addUrlParams(key, value) {
        if (value)
            searchParams.set(key, value)
        navigate(location.pathname + "?" + searchParams.toString())

    }

    function convEnNum(str) {
        return parseFloat(str
            .replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)) // Convert Arabic numbers
            .replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)) // Convert Persian numbers
        );
    }

    function sendDateToApiForFilter() {

        addUrlParams("brandNameFA", brandNameFA)
        addUrlParams("brandNameEN", brandNameEN)
        addUrlParams("genericNameFA", genericNameFA)
        addUrlParams("genericNameEN", genericNameEN)
        addUrlParams("genericCode", genericCode)
        addUrlParams("quatityInPack", quatityInPack)
        addUrlParams("supplirCompany", supplirCompany)
        addUrlParams("producerComopany", producerComopany)
        addUrlParams("ircCode", ircCode)
        addUrlParams("pricePharmacy", pricePharmacy)
        addUrlParams("priceDistributor", priceDistributor)
        props.setShowFilterPage(false)

    }


    return (
        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity">
            <div className="flex min-h-full p-3">
                <div className="rounded-lg bg-white w-[60%] md:w-[88%] p-8 md:p-0 md:pt-8 md:pb-8 m-auto gap-5">
                    <div
                        className="grid grid-cols-2 md:h-[70vh] md:overflow-y-scroll md:flex md:flex-col md:gap-8 md:mt-3">

                        <div className="col-span-1 flex flex-col gap-8">

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={brandNameFA}
                                        id="brandNameFA"
                                        onChange={(e) => setBrandNameFA(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="brandNameFA">
                                        نام برند دارویی به فارسی
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={genericNameFA}
                                        id="genericNameFA"
                                        onChange={(e) => setGenericNameFA(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="genericNameFA">
                                        نام ژنریک دارویی به فارسی
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="number"
                                        value={quatityInPack}
                                        id="quatityInPack"
                                        onChange={(e) => setQuatityInPack(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="quatityInPack">
                                        مقدار در بسته
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={producerComopany}
                                        id="producerCompany"
                                        onChange={(e) => setProducerComopany(e.target.value)}

                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="producerCompany">
                                        شرکت تولید کننده
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="number"
                                        value={priceDistributor}
                                        id="priceDistributor"
                                        onChange={(e) => setPriceDistributor(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text"
                                           htmlFor="priceDistributor">
                                        قیمت فروش به توزیع کننده
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="number"
                                        value={pricePharmacy}
                                        id="pricePharmacy"
                                        onChange={(e) => setPricePharmacy(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="pricePharmacy">
                                        قیمت فروش به داروخانه
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className="col-span-1 flex flex-col gap-8">

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={brandNameEN}
                                        id="brandNameEN"
                                        onChange={(e) => setBrandNameEN(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="brandNameEN">
                                        نام برند دارویی به لاتین
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={genericNameEN}
                                        id="genericNameEN"
                                        onChange={(e) => setGenericNameEN(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="genericNameEN">
                                        نام ژنریک دارویی به لاتین
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="number"
                                        value={genericCode}
                                        id="genericCode"
                                        onChange={(e) => setGenericCode(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="genericCode">
                                        کد ژنریک
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={supplirCompany}
                                        id="supplirCompany"
                                        onChange={(e) => setSupplirCompany(e.target.value)}
                                        placeholder=""
                                        className="input1 outline-none bg-transparent w-full h-full"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="supplirCompany">
                                        شرکت تامین کننده
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="number"
                                        value={ircCode}
                                        id="ircCode"
                                        onChange={(e) => setIrcCode(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="ircCode">
                                        کد IRC
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="flex justify-center gap-[10px] mt-8">
                        <button
                            className="border-[3px] w-[18%] h-[36px] md:w-[33%] text-[14px] rounded-[25px] border-mainColor bg-mainColor text-white"
                            onClick={sendDateToApiForFilter}>اعمال
                            فیلتر
                        </button>
                        <button onClick={() => props.setShowFilterPage(false)}
                                className="border-[3px] w-[18%] h-[36px] text-[14px] md:w-[33%] rounded-[25px] border-mainColor text-mainColor">
                            انصراف
                        </button>
                    </div>

                </div>


            </div>
        </div>
    )
}