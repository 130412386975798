import React from "react";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {useOutletContext} from "react-router-dom";


const CardComponent = ({title, duration, price, features, buttonText, bgColor,}) => {

    const {handleNextStep} = useOutletContext();
    console.log(handleNextStep)

    return (
        <div className="bg-lightColor w-96 h-auto rounded-[5px] md:w-auto md:h-auto">
            <div
                style={{backgroundColor: bgColor}}
                className={` w-[80%] h-24 rounded-bl-[60px] flex items-center`}
            >
                <div className="flex items-center gap-2 mr-5">
                    <DiamondOutlinedIcon
                        style={{fontSize: "38px"}}
                        className="text-white mt-2"
                    />
                    <p className="text-white text-[32px] font-bold">{title}</p>
                </div>
            </div>
            <div className="bg-[#E5E5E5] w-[45%] h-12 rounded-bl-[20px]">
                <div className="flex mr-6 items-center h-full gap-3">
                    <AccessTimeOutlinedIcon
                        style={{fontSize: "26px"}}
                        className="text-gray-500"
                    />
                    <p className="text-[20px] text-[#2E2C38]">{duration}</p>
                </div>
            </div>

            <div className="flex justify-start">
                <div className="flex flex-col mt-6 w-auto mr-16 p-2">
                    <div className="flex flex-col">
                        <div className="flex items-center gap-2 h-auto">
                            <p className="text-cardPrice text-[32px]">{price}</p>
                            <span className="mt-2 text-[16px] text-cardText">ریال</span>
                        </div>
                        <div className="flex mt-6 flex-col gap-2">
                            {features.map((feature, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <FiberManualRecordIcon
                                        style={{fontSize: "18px"}}
                                        className="text-gray-300"
                                    />
                                    <p className="text-[22px] text-cardText">{feature}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <div className="w-[75%] flex justify-center items-center mb-6 mt-6">
                    <button onClick={handleNextStep}
                            style={{backgroundColor: bgColor}}
                            className={` w-full h-16 rounded-[8px]`}
                    >
                        <p className="text-white text-[22px]">{buttonText}</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CardComponent;
