import React, {useState, useEffect} from "react";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
// import DehazeIcon from '@mui/icons-material/Dehaze';
// import {useMediaQuery} from 'react-responsive';
import ProfileHeader from "./ProfileHeader";
import Header from "../../Headers/Header";
import LockResetIcon from '@mui/icons-material/LockReset';
import axios from 'axios';
import {CheckAndHandleError} from "../../../axios/HandleAcsess";
import Loading from "../../Loading/Loading";
// import img from "../../../images/paymentpic.webp";
import {message, Modal, Skeleton} from "antd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NearMeIcon from "@mui/icons-material/NearMe";

// import {useNavigate} from "react-router-dom";


function Profile() {

    // const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [loadingProvinces, setLoadingProvinces] = useState(false);


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [orgName, setOrgName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [lastLogin, setLastLogin] = useState("");
    const [profile, setProfile] = useState(null);


    const [listProvinces, setListProvinces] = useState([]);
    const [selectedProvinceID, setSelectedProvinceID] = useState("");
    const [selectedProvinceName, setSelectedProvinceName] = useState("");
    const [showModalChangePassword, setShowModalChangePassword] = useState(false);


    useEffect(() => {
        setListProvinces(true)
        axios.get("https://kimipedia-back.liara.run/states/v1/get-state-list")
            .then((response) => {
                setListProvinces(response.data.data.docs);
                setLoadingProvinces(false)
            }).catch(() => {
            setLoadingProvinces(true)
        })
    }, []);

    const loadProfile = () => {
        axios.get('https://kimipedia-back.liara.run/user/v1/get-my-profile', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(response => {
                const data = response.data.data;
                setProfile(data);
                setFirstName(data?.first_name);
                setLastName(data?.last_name);
                setMobile(data?.mobile);
                setOrgName(data?.org_name);
                setAddress(data?.address);
                setPhone(data?.phone);
                setEmail(data?.email);
                setLastLogin(formatTimestamp(data?.last_login));
                // setStateName(data.state || "");
                setSelectedProvinceName(data?.state?.name);
                setSelectedProvinceID(data?.state?._id)
            })
            .catch(error => {
                if (error.message === "Network Error") {
                    message.error("اینترنت خود را بررسی کنید ..!")
                }else if ( error.response ) {
                    message.error(error.response.data.message);
                } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
                CheckAndHandleError(error);
            });
    };

    useEffect(() => {
        loadProfile();
    }, [refreshTrigger]);

    const handleSubmit = (e) => {

        e.preventDefault();
        setLoading(true);


        const updatedProfile = {
            first_name: firstName,
            last_name: lastName,
            org_name: orgName,
            address: address,
            state: selectedProvinceID,
            email: email,
            phone: phone,
        };

        axios.put('https://kimipedia-back.liara.run/user/v1/update-profile-by-user', updatedProfile, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(response => {
                message.success(response.data.message)
                setLoading(false);
                if (response.status === 200) {
                    setRefreshTrigger(prev => !prev);
                    setShowModal(false);
                }
            })
            .catch( error => {
                if (error.message === "Network Error") {
                    message.error("اینترنت خود را بررسی کنید ..!")
                }else if ( error.response ) {
                    message.error(error.response.data.message);
                } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
                setShowModal(false);
                setLoading(false);
                CheckAndHandleError(error);
            });
    };

    const handleCancel = () => {
        setRefreshTrigger(prev => !prev);
    }

    const selectProvince = (props) => {
        setSelectedProvinceID(props._id);
        setSelectedProvinceName(props.name);
        // setStateName(props.name);
        setShowDropDown(false);
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(parseInt(timestamp));
        return date.toLocaleString('fa-IR');
    };

    // if (error) return <p>{error}</p>;


    return (

        <React.Fragment>

            <Header/>

            <Modal width={500} open={showModal} className="font-bold"
                   footer={null} onCancel={null}>
                <div className="flex items-center md:flex md:flex-col-reverse md:gap-8">
                    <div className="w-full md:w-full flex flex-col gap-8">

                        <h4 className="text-[16px] md:text-[16px] ">
                            آیا از اعمال تغییرات اطمینان دارید ؟ </h4>

                        <div className="flex justify-center gap-4">
                            <button onClick={handleSubmit}
                                    className="w-[128px] bg-mainColor h-[36px] flex items-center justify-center rounded-lg">
                                {loading ? (<Loading color={"bg-white"}/>) : (
                                    <p className="text-white text-[16px]">بله</p>
                                )}
                            </button>
                            <button onClick={() => setShowModal(false)}
                                    className="w-[128px] bg-red-600 h-[36px] rounded-lg">
                                <p className="text-white text-[16px]">خیر</p>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="grid grid-cols-12 h-screen md:h-screen md:contents md:contents-col-12">
                <div className="col-span-2 bg-mainColor md:fixed md:inset-x-0 md:bottom-0 md:col-span-2">
                    <ProfileHeader/>
                </div>
                <div className="col-span-10 md:col-span-10">
                    <div className="flex justify-center items-center md:mb-[100px]">
                        <div className="w-[88%] md:w-[90%]">
                            {!profile ? (
                                <div className="flex justify-center mt-8">
                                    <Loading color={"bg-mainColor"}/>
                                </div>
                            ) : (
                                <div>
                                    <div className="mt-[55px] md:mt-4">
                                        <div className="grid md:grid-cols-1 grid-cols-2">
                                            <div className="col-span-1 flex">
                                                <div className="w-full">
                                                    <div className="grid items-center">
                                                        <div className="flex">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">نام</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <input
                                                                className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                                placeholder={firstName || "نام کاربری"}
                                                                value={firstName}
                                                                onChange={(e) => setFirstName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">نام خانوادگی</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <input
                                                                className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                                placeholder={lastName || "نام خانوادگی کاربر"}
                                                                value={lastName}
                                                                onChange={(e) => setLastName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">تلفن همراه</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 hover:cursor-context-menu w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <p className="bg-transparent w-full h-[24px] outline-none pr-[12px]">{mobile}</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">نام مرکز</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <input
                                                                className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                                placeholder={orgName || "نام مرکز"}
                                                                value={orgName}
                                                                onChange={(e) => setOrgName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">آدرس</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <input
                                                                className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                                placeholder={address || "آدرس کاربر"}
                                                                value={address}
                                                                onChange={(e) => setAddress(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:mt-8">

                                                <div className="w-full">

                                                    <div className="">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">تلفن ثابت</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <input
                                                                className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                                placeholder={phone || "تلفن ثابت کاربر"}
                                                                value={phone}
                                                                onChange={(e) => setPhone(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">آدرس ایمیل</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <input
                                                                className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                                placeholder={email || "آدرس ایمیل کاربر"}
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">استان</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] flex md:w-full bg-gray-200 rounded-lg"
                                                            onClick={() => setShowDropDown(showDropDown => !showDropDown)}>
                                                            <p
                                                                className="hover:cursor-context-menu bg-transparent w-full h-[24px]  outline-none pr-[12px]"
                                                                // onChange={(e) => setSelectedProvinceName(e.target.value)}
                                                            > {selectedProvinceName || "استان کابر"}</p>
                                                            <div
                                                                className={`${showDropDown ? `rotate-180` : null}`}>
                                                                <KeyboardArrowDownIcon/>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-start mt-2">
                                                            {showDropDown ?
                                                                loadingProvinces ?
                                                                    <div
                                                                        className="z-10 absolute overflow-y-scroll h-[17vh] w-[30.5%] md:w-[90%] bg-gray-200 rounded-[5px]">
                                                                        <ul className="flex flex-col gap-3 w-full">
                                                                            <li className="m-auto mt-5 w-[90%] text-mainColor">
                                                                                <Skeleton active paragraph={{
                                                                                    rows: 3,
                                                                                    width: "100%"
                                                                                }} title={false}/>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        className="z-10 absolute overflow-y-scroll h-[17vh] w-[30.5%] md:w-[90%] bg-gray-200 rounded-[5px]">
                                                                        <ul>
                                                                            {listProvinces.map((product) => (
                                                                                <li key={product._id}
                                                                                    className="m-3 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                                                    onClick={() => selectProvince(product)}
                                                                                ><NearMeIcon
                                                                                    className="ml-[5px] text-mainColor"/>{product.name}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">آخرین ورود</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                            <p className="bg-transparent w-full h-[24px] pr-[12px]">
                                                                {lastLogin}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="mt-8">
                                                        <div className="flex items-center">
                                                            <ArrowBackIosNewOutlinedIcon
                                                                className="text-mainColor text-[17px]"/>
                                                            <h3 className="text-[15px]">ویرایش رمز عبور</h3>
                                                        </div>
                                                        <div
                                                            className="p-3 mt-2 w-[82%] flex md:w-full justify-between bg-gray-200 rounded-lg hover:cursor-pointer"
                                                            onClick={() => setShowModalChangePassword(true)}>
                                                            <p className="bg-transparent w-full h-[24px] pr-[12px]">
                                                                ******************
                                                            </p>
                                                            <LockResetIcon/>
                                                        </div>
                                                    </div>


                                                    <div className="md:mt-[32px] mt-[56px]">
                                                        <div className="flex gap-2">
                                                            <button
                                                                onClick={() => setShowModal(true)}
                                                                className="w-[40%] md:w-[49%] md:h-[36px] h-[47px] rounded-[6px] bg-mainColor text-white">
                                                                ویرایش
                                                            </button>
                                                            <button onClick={handleCancel}
                                                                    className="border-[3px] md:h-[36px] w-[40%] md:w-[49%] h-[47px] rounded-[6px] border-mainColor text-mainColor">
                                                                انصراف
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showModalChangePassword ?
                <ChangePassword setShowModalChangePassword={setShowModalChangePassword}/>
                :
                null}

        </React.Fragment>
    );
}

export default Profile;


export function ChangePassword(props) {

    // const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [reNewPassword, setReNewPassword] = useState("");
    const [somePasswordError, setSomePasswordError] = useState(false);
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (newPassword === reNewPassword)
            setSomePasswordError(true)
        else setSomePasswordError(false)
    }, [newPassword, reNewPassword]);


    function sendNewPasswordToApi() {

        if (somePasswordError) {
            setLoading(true)
            axios.put("https://kimipedia-back.liara.run/user/v1/change_password", {
                newpassword: newPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then((response) => {
                message.success(response.data.message)
                props.setShowModalChangePassword(false)
                setLoading(false)
            }).catch((error) => {
                message.error(error.response.data.message);
                setLoading(false)
            })
        } else {
            message.error("رمز عبور یکسان نیست");
        }
    }

    return (

        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity">
            <div className="flex min-h-full p-3">
                <div className="rounded-lg bg-white w-[60%] md:w-[88%] p-8 m-auto gap-5">

                    <div className="grid grid-cols-2 md:flex md:flex-col">
                        <div className="col-span-1 flex flex-col md:flex md:flex-col gap-8">

                            <div className="w-full">
                                <div>
                                    <div className="flex mt-8 items-center">
                                        <ArrowBackIosNewOutlinedIcon
                                            className="text-mainColor text-[17px]"/>
                                        <h3 className="text-[15px]">رمز عبور جدید</h3>
                                    </div>
                                    <div
                                        className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                        <input
                                            className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            type={"password"} placeholder="رمز عبور جدید"></input>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="col-span-1 flex flex-col gap-8">

                            <div className="w-full">
                                <div>
                                    <div className="flex mt-8 items-center">
                                        <ArrowBackIosNewOutlinedIcon
                                            className="text-mainColor text-[17px]"/>
                                        <h3 className="text-[15px]">تکرار رمز عبور جدید</h3>
                                    </div>
                                    <div
                                        className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                        <input
                                            className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                            onChange={(e) => setReNewPassword(e.target.value)}
                                            type={"password"} placeholder="تکرار رمز عبور جدید"></input>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="flex gap-2">
                                    <button
                                        onClick={sendNewPasswordToApi}
                                        className="w-[40%] md:w-[49%] h-[35px] rounded-[6px] bg-mainColor text-white flex justify-center items-center">
                                        {loading ? (
                                            <Loading color={"bg-white"}/>) : (<p>ویرایش</p>)}
                                    </button>
                                    <button onClick={() => props.setShowModalChangePassword(false)}
                                            className="border-[3px] w-[40%] md:w-[49%] h-[35px] rounded-[6px] border-mainColor text-mainColor">
                                        انصراف
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>


            </div>
        </div>
    )
}