import React, {useEffect, useState} from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import axios from "axios";
import {message, Skeleton} from "antd";
import Loading from "../../Loading/Loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";


function MainProblems(props) {


    const [ticketList, setTicketList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);
    const itemsPerPage = 10;

    console.log(ticketList)

    useEffect(() => {
        // const fetchTickets = async () => {
        setLoading(true);
        axios.get("https://kimipedia-back.liara.run/ticket/v1/ticket_list_user", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                limit: itemsPerPage,
                page: currentPage
            }
        }).then((response) => {
            setTotalDocs(response.data.data.totalDocs);
            setTicketList(response.data.data.docs);
            setTotalPages(response.data.data.totalPages);
            setLoading(false)
        }).catch((error) => {
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }else if ( error.response ) {
                message.error(error.response.data.message);
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
            setLoading(false);
        })
        // };

    }, [currentPage]);


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp);
        return date.toLocaleString('fa-IR');
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const sendDatetoTitleProblem = (ticket) => {
        props.setTitleReport(ticket.title)
        props.setTextReport(ticket.report)
        props.setTimeReport(formatTimestamp(ticket.date))
        props.setAnswer(ticket.answer)
        if (ticket.status === "not checked") props.setSatus("بررسی نشده")
        if (ticket.status === "checked") props.setSatus("بررسی شده")

        props.setShowProblem(true)
    }

    const getStartingIndex = () => (currentPage - 1) * itemsPerPage + 1;


    return (
        <React.Fragment>
            <div className="flex justify-center items-center">
                <div className="w-[88%]">
                    <div className="grid grid-col-3">
                        <div className="col-span-1">
                            <div className="flex justify-between items-center mt-[50px]">
                                <p className="text-[19px] md:text-[14px]">لیست گزارشات مشکلات سامانه</p>
                                <button onClick={() => props.setAddProblem(true)}
                                        className="md:text-[12px] h-[43px] w-[18%] md:w-[34%] md:h-[35px] border-mainColor border-[3px] rounded-[9px]">
                                    ارسال گزارش جدید
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <table
                                className="border-separate border-slate-500 mt-[30px] w-full text-center md:text-[12px]">
                                <thead className="md:text-[13px]">
                                <tr className="h-[50px]">
                                    <th className="border border-white bg-lightColor">ردیف</th>
                                    <th className="border border-white bg-lightColor">عنوان گزارش ارسال
                                        شده
                                    </th>
                                    <th className="border border-white bg-lightColor">تاریخ ارسال</th>
                                    <th className="border border-white bg-lightColor">عملیات</th>
                                </tr>
                                </thead>
                                <tbody>
                                {loading ? (
                                        [...Array(7)].map((_, index) => (
                                            <tr key={index}>
                                                <td className="box-border text-mainColor">
                                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                                </td>
                                                <td className="box-border text-mainColor">
                                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                                </td>
                                                <td className="box-border text-mainColor">
                                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                                </td>
                                                <td className="box-border text-mainColor">
                                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                                </td>
                                                <td className=" md:hidden box-border text-mainColor">
                                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                                </td>
                                                <td className=" md:hidden box-border text-mainColor">
                                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    :
                                    ticketList.length > 0 ?
                                        ticketList.map((ticket, index) => (
                                            <tr key={ticket._id} className={`h-[70px] md:text-[11px] ${index % 2 === 0 ? 'bg-white' : 'bg-[#F9F9F9]'} `} >
                                                <td className="border border-white">{getStartingIndex() + index}</td>
                                                <td className="border border-white">{ticket.title.slice(0, 20)} {ticket.title.length > 20 ? "..." : null}</td>
                                                <td className="border border-white">{formatTimestamp(ticket.date)}</td>
                                                <td className="border border-white">
                                                    <button onClick={() => sendDatetoTitleProblem(ticket)}
                                                            className="border-b-[2px] md:border-none border-mainColor text-mainColor">نمایش
                                                        گزارش
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td className="bg-lightColor" colSpan="4">هیچ گزارشی یافت نشد.</td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>

                        {totalDocs > itemsPerPage ?

                            // <div className="col-span-1 mb-4">

                                <div className="pagination transition ease-in-out delay-150 p-5 text-mainColor flex items-center justify-center gap-3">
                                    <button
                                        className={` ${currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                                            <ArrowForwardIcon style={{fontSize: "18px"}}/>
                                        </div>
                                    </button>
                                    <p className="text-black md:text-[12px] font-bold">{currentPage} از {totalPages} صفحه</p>
                                    <button
                                        className={` ${currentPage === totalPages ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                                            <ArrowBackIcon style={{fontSize: "18px"}}/>
                                        </div>
                                    </button>
                                </div>

                            // </div>
                            : null}

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainProblems;
